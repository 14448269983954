export const photoSizes = {
  '49439006538': { w: 5.33, h: 8 },
  '49439705277': { w: 5.33, h: 8 },
  '50070449343': { w: 8, h: 5.96 },
  '50085097008': { w: 8, h: 5.62 },
  '50102624687': { w: 8, h: 5.75 },
  '50102627707': { w: 8, h: 5.65 },
  '50135105553': { w: 8, h: 5.34 },
  '50135150253': { w: 8, h: 5.15 },
  '50138183042': { w: 8, h: 5.32 },
  '50142827867': { w: 8, h: 5.35 },
  '50142821082': { w: 8, h: 5.67 },
  '50142825402': { w: 8, h: 5.12 },
  '50167427588': { w: 8, h: 4.58 },
  '50167428173': { w: 8, h: 6.05 },
  '50167961821': { w: 8, h: 5.56 },
  '50167962716': { w: 8, h: 5.64 },
  '50168225882': { w: 8, h: 5.55 },
  '50175951808': { w: 8, h: 4.97 },
  '50175952333': { w: 8, h: 5.25 },
  '50182656558': { w: 8, h: 5.38 },
  '50183199661': { w: 8, h: 5.2 },
  '50183201051': { w: 8, h: 5.8 },
  '50183201401': { w: 8, h: 4.91 },
  '50183201546': { w: 8, h: 5.37 },
  '50182658663': { w: 8, h: 4.99 },
  '50194175467': { w: 8, h: 5.2 },
  '50193918111': { w: 8, h: 5.56 },
  '50208722603': { w: 8, h: 5.42 },
  '50209247186': { w: 8, h: 5.27 },
  '50216956991': { w: 8, h: 5.34 },
  '24038031401': { w: 8, h: 3.29 },
  '23492400764': { w: 5.33, h: 8 },
  '23824965140': { w: 5.33, h: 8 },
  '23824959290': { w: 5.33, h: 8 },
  '24120603275': { w: 8, h: 3.63 },
  '24052101302': { w: 5.33, h: 8 },
  '50071261957': { w: 5.33, h: 8 },
  '19032192509': { w: 8, h: 5.32 },
  '19212661382': { w: 5.33, h: 8 },
  '19032384599': { w: 7.55, h: 8 },
  '18597968683': { w: 5.33, h: 8 },
  '19283043975': { w: 5.33, h: 8 },
  '18690797394': { w: 8, h: 4.12 },
  '23493796583': { w: 5.33, h: 8 },
  '23824935810': { w: 5.33, h: 8 },
  '23752792879': { w: 5.33, h: 8 },
  '24160179005': { w: 8, h: 6.26 },
  '24134072306': { w: 5.33, h: 8 },
  '14344166368': { w: 5.33, h: 8 },
  '14344165958': { w: 5.33, h: 8 },
  '14550862813': { w: 5.33, h: 8 },
  '14550861373': { w: 5.33, h: 8 },
  '19032166049': { w: 5.33, h: 8 },
  '19030727308': { w: 5.33, h: 8 },
  '14314764839': { w: 5.33, h: 8 },
  '14501375525': { w: 5.33, h: 8 },
  '19030723158': { w: 5.33, h: 8 },
  '18597885673': { w: 5.33, h: 8 },
  '23824616850': { w: 5.33, h: 8 },
  '24012481342': { w: 5.33, h: 8 },
  '23531971664': { w: 5.33, h: 8 },
  '49637579552': { w: 8, h: 4.35 },
  '14307517018': { w: 5.33, h: 8 },
  '14307515948': { w: 5.33, h: 8 },
  '14492819994': { w: 5.33, h: 8 },
  '14307505629': { w: 5.33, h: 8 },
  '14307509958': { w: 5.33, h: 8 },
  '19192208506': { w: 5.33, h: 8 },
  '23792380169': { w: 8, h: 5.36 },
  '23864534120': { w: 8, h: 2.76 },
  '49636775803': { w: 5.33, h: 8 },
  '14169395299': { w: 5.33, h: 8 },
  '14355205294': { w: 5.33, h: 8 },
  '14169626897': { w: 5.33, h: 8 },
  '14355250754': { w: 5.33, h: 8 },
  '14355249554': { w: 5.33, h: 8 },
  '14169630177': { w: 5.33, h: 8 },
  '14377877562': { w: 5.33, h: 8 },
  '14192703038': { w: 5.33, h: 8 },
  '14375982071': { w: 5.33, h: 8 },
  '14377871102': { w: 5.33, h: 8 },
  '14192694318': { w: 5.33, h: 8 },
  '14192872177': { w: 5.33, h: 8 },
  '14209148297': { w: 5.33, h: 8 },
  '14471008846': { w: 5.33, h: 8 },
  '14307652467': { w: 5.33, h: 8 },
  '14307466210': { w: 5.33, h: 8 },
  '14314767019': { w: 5.33, h: 8 },
  '14314771838': { w: 5.33, h: 8 },
  '19283029215': { w: 6.44, h: 8 },
  '19095454688': { w: 7.03, h: 8 },
  '18660556884': { w: 5.53, h: 8 },
  '24052107042': { w: 5.33, h: 8 },
  '23792368189': { w: 5.33, h: 8 },
  '49637299866': { w: 5.33, h: 8 },
  '14354528482': { w: 5.33, h: 8 },
  '18660571294': { w: 4.95, h: 8 },
  '19286955351': { w: 5.33, h: 8 },
  '19095431170': { w: 5.33, h: 8 },
  '19095461968': { w: 5.33, h: 8 },
  '19095460538': { w: 8, h: 7.07 },
  '19096922899': { w: 5.33, h: 8 },
  '23792360129': { w: 5.33, h: 8 },
  '49637574117': { w: 8, h: 6.03 },
  '49637310551': { w: 8, h: 6.05 },
  '50070444363': { w: 5.33, h: 8 },
  '50071263302': { w: 7.86, h: 8 },
  '50072194622': { w: 8, h: 7.74 },
  '50085681416': { w: 8, h: 5.48 },
  '50102626432': { w: 8, h: 5.51 },
  '49462340946': { w: 8, h: 8 },
};
